<template>
  <div class="py-5">
    <div class="pb-5 my-4">
      <b-card-title class="pb-2 mb-0 text-med-18 text-font-main">
        {{ $t('common.sign_in') }}
      </b-card-title>
      <b-card-text class="mt-2 text-reg-14 text-font-secondary">
        {{ $t('common.please_enter_password_email') }}
      </b-card-text>
    </div>
    <wameed-form @onSubmit="login">
      <!-- form -->
      <div slot-scope="{ invalid }">
        <!-- email -->
        <div class="pt-1">
          <wameed-text-input
              id="login-email"
              v-model="form.email"
              :label="$t('common.email')"
              field-classes="pb-1 login-inputs"
              input-classes="text-reg-14 "
              name="email"
              placeholder="email@example.com"
              rules="required|email"
          />

          <!-- forgot password -->
          <wameed-text-input
              id="login-password"
              v-model="form.password"
              :icon="passwordToggleIcon"
              :label="$t('common.password')"
              :type="passwordType"
              field-classes=" login-inputs mb-2"
              input-classes="text-reg-14"
              isAppend
              name="password"
              placeholder="*******"
              rules="required|min:6"
              @onPostIconClick="togglePassword()"
          />
          <div class="d-flex justify-content-end">
            <wameed-btn
                :classes="'px-0 text-medium-14 text-font-sub my-1'"
                :title="$t('common.forget_password')"
                variant="link"
                @onClick="()=>goTo('forgot-password')"
            />
          </div>

          <!-- submit buttons -->
          <wameed-btn
              :classes="' change-icon-position text-reg-14 text-white d-flex align-items-center rounded-10  justify-content-between my-2'"
              :disabled="invalid"
              :title="$t('common.sign_in')"
              block
              type="submit"
              variant="main"
          />

        </div>
      </div>
    </wameed-form>
  </div>
</template>

<script>
/* eslint-disable global-require */


import {WameedBtn, WameedForm, WameedTextInput} from 'wameed-ui/dist/wameed-ui.esm';

export default {
  components: {
    WameedTextInput,
    WameedForm,
    WameedBtn,
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
        rememberMe: false,
      },
      passwordType: 'password',
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordType === 'password' ? 'eyeon-icon' : 'eyeoff-icon';
    },
  },
  methods: {
    login() {
      this.$store.dispatch('auth/login', this.form)
    },
    togglePassword(){
     this.passwordType= this.passwordType === 'password' ? 'text' : 'password';
    },
    async goTo(value) {
      await this.$router.push({name: value, force: true});
    },
  },
};
</script>
